<template>
  <div class="layout-header-section d-lg-flex d-block justify-content-between">
    <div class="header-label col-lg-12 col-12">Delete My Profile</div>
  </div>
  <div class="layout-box-content-section mb-3" style="height: calc(100vh - 157px)">
    <div class="row">
      <!-- <div class="col-2 mb-4"> -->
      <div class="col-lg-8 col-md-8 col-12 " style="height: 160px;">
        <div class="row">
          <div class="col-lg-3 col-md-3 col-4">
            <div class="m-3">
              <div class="myprofile-avatar-outer" v-if="setProfileTypePhoto == 1">
                <img v-if="aadharprofileimage != null && aadharprofileimage != ''"
                  :src="'https://storage.googleapis.com/' + this.voterstorageimgpath + '/aadharphoto/' + aadharprofileimage"
                  class="myprofile-avatar rounded-circle" alt="" width="100" height="100" />
              </div>
              <div class="myprofile-avatar-outer" v-else-if="setProfileTypePhoto == 2">
                <img v-if="voterprofileimg != null && voterprofileimg != ''"
                  :src="'https://storage.googleapis.com/' + this.voterstorageimgpath + '/voterphoto/' + voterprofileimg"
                  class="myprofile-avatar rounded-circle" alt="" width="100" height="100" />
              </div>
              <div class="myprofile-avatar-outer" v-else>
                <img 
                  src="/assets/images/icon/customers-tab-icon.svg" class="myprofile-avatar rounded-circle test1" alt=""
                  width="100" height="100" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-12 align-self-end text-end">
        <div class="custom-form-group">
          <button type="button" @click="confirmDeleteProfileModalOpen" class="btn profile-delete-btn ms-3">
            <i class="pi pi-trash me-1" style="font-size: 12px;"></i>Delete Profile
          </button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <div class="custom-form-group">
          <label for="formfirstnameInput" class="form-label">First Name</label>
          <div class="input-group custom-input-group">
            <div class="col-lg-4 col-md-4 col-12">
              <Multiselect v-model="profileupdate.title" :options="titleList" :searchable="false" label="label"
                placeholder="Select" class="multiselect-custom custom-title-multiselect text-capitalize"
                :canClear="false" :closeOnSelect="true" :object="true" disabled noOptionsText="No Result"
                :canDeselect="false" />
            </div>
            <div class="col-lg-8 col-md-8 col-12">
              <input type="text" v-model="profileupdate.firstname" class="form-control text-capitalize"
                id="formfirstnameInput" placeholder="First Name" autocomplete="off" disabled
                @keypress="isLetterWithOutSpace($event)" />
            </div>
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="custom-form-group">
          <label for="formmdnameInput" class="form-label">Middle Name</label>
          <input type="text" v-model="profileupdate.middlename" class="form-control text-capitalize"
            id="formmdnameInput" placeholder="Middle Name" autocomplete="off" disabled
            @keypress="isLetterWithOutSpace($event)" />
        </div>
      </div>
      <div class="col-3">
        <div class="custom-form-group">
          <label for="formlastnameInput" class="form-label">Last Name</label>
          <input type="text" v-model="profileupdate.lastname" class="form-control text-capitalize"
            id="formlastnameInput" placeholder="Last Name" autocomplete="off" disabled
            @keypress="isLetterWithOutSpace($event)" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <div class="custom-form-group">
          <label for="formemailInput" class="form-label">Email Id</label>
          <input type="text" v-model="profileupdate.email" class="form-control" id="formemailInput"
            placeholder="Email Id" autocomplete="off" disabled />
        </div>
      </div>
      <div class="col-3">
        <div class="custom-form-group">
          <label for="formcontactInput" class="form-label">Mobile No.</label>
          <div class="input-group custom-input-group">
            <div class="col-lg-4 col-md-4 col-12">
              <Multiselect v-model="profileupdate.countrycode" :options="countryCodeList" :searchable="true"
                label="label" placeholder="Select" class="multiselect-custom custom-title-multiselect" :canClear="false"
                :closeOnSelect="true" :object="true" disabled noOptionsText="No Result" :canDeselect="false" />
            </div>
            <div class="col-lg-8 col-md-8 col-12">
              <input type="text" v-model="profileupdate.mobileno" class="form-control" id="formcontactInput"
                placeholder="Mobile No" autocomplete="off" disabled maxlength="10" @paste="onPasteMobile"
                @keypress="onlyNumber" />
            </div>
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="custom-form-group">
          <label for="formaadharInput" class="form-label">Aadhar No.</label>
          <input type="text" id="formaadharInput" v-model="profileupdate.useraadharno" class="form-control"
            placeholder="N/A" autocomplete="off" disabled />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <div class="custom-form-group">
          <label for="formvoterInput" class="form-label">Voter Id</label>
          <input type="text" id="formvoterInput" v-model="profileupdate.uservoderid" class="form-control text-uppercase"
            placeholder="N/A" autocomplete="off" disabled />
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-12">
        <div class="custom-form-group">
          <label for="formaddressInput" class="form-label">Current Address</label>
          <textarea type="text" id="formaddressInput"
            v-if="profileupdate.useraadharaddress != null && profileupdate.useraadharaddress != ''"
            v-model="profileupdate.useraadharaddress" class="form-control custom-text-area-scroll" placeholder="N/A"
            disabled autocomplete="off" rows="2" maxlength="250"></textarea>
          <textarea type="text" id="formaddressInput"
            v-if="profileupdate.useraadharaddress == null || profileupdate.useraadharaddress == ''"
            v-model="profileupdate.usercurrentaddress" class="form-control custom-text-area-scroll" placeholder="N/A"
            disabled autocomplete="off" rows="2" maxlength="250"></textarea>
        </div>
      </div>
    </div>
    <Divider class="mt-4" />
    <div class="row">
      <div class="col-lg-6 col-md-6 col-12 my-3 ">
        <div class="d-flex justify-content-left">
          <div class="mx-4 imagehover" @click="viewprofileaadharModalOpen()">
            <img src="/assets/images/doc/profile_aadhar.jpg" class="transition-effect-card-outer" alt="aadhar card"
              width="300" />
          </div>
          <div class="d-flex align-items-center">
            <button type="button" @click="viewprofileaadharModalOpen()" class="btn reset-password-submit-btn"
              style="width: 80px;">View
            </button>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-12 my-3">
        <div class="d-flex justify-content-left">
          <div class="mx-4 imagehover" @click="viewprofilevoterModalOpen()">
            <img src="/assets/images/doc/profile_voter.jpg" class="transition-effect-card-outer" alt="voter card"
              width="300" />
          </div>
          <div class="d-flex align-items-center">
            <button type="button" @click="viewprofilevoterModalOpen()" class="btn reset-password-submit-btn"
              style="width: 80px;">View
            </button>
          </div>
        </div>

      </div>
    </div>
  </div>
  <!-- view aadhar modal start here -->
  <div class="modal-mask" v-if="viewaadhardetailmodalflag">
    <div class="modal-dialog modal-lg modal-dialog-centered custom-modal-outer">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">Aadhar Details</h5>
          <button type="button" class="btn-close" @click="viewprofileaadharModalClose()"></button>
        </div>
        <div class="modal-body ticket-history-box-outer">
          <div class="modal-aadhar-scroll-min-height">
            <div v-if="!showloaderaadharmodal">
              <div v-if="aadhardivdisplayflag == 1">
                <div class="row">
                  <div class="
                        col-lg-2 col-md-2 col-12
                        d-flex
                        align-items-center
                      ">
                    <div class="customer-label-group">
                      <div class="d-flex align-items-center">
                        <div>
                          <img v-if="AadharTabDetails.ak33 == null ||
              AadharTabDetails.ak33 == ''
              " src="/assets/images/profile.svg" class="rounded bg-light me-auto d-block" alt="" width="90" />
                          <img
                            :src="'https://storage.googleapis.com/' + this.voterstorageimgpath + '/aadharphoto/' + AadharTabDetails.ak33"
                            class="rounded bg-light me-auto d-block" alt="" width="90" v-if="AadharTabDetails.ak33 != null &&
              AadharTabDetails.ak33 != ''
              " />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-4 col-12">
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Name</label>
                          <div class="from-label-value text-capitalize">
                            {{
              AadharTabDetails.ak5
                ? AadharTabDetails.ak5
                : "N/A"
            }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-12 col-md-12 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Aadhar Number</label>
                          <div class="from-label-value">
                            {{ maskedNumber(AadharTabDetails.ak36) ? maskedNumber(AadharTabDetails.ak36) : "N/A" }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-4 col-md-4 col-12">
                    <div class="customer-label-group mb-2">
                      <label class="form-label">Date of Birth</label>
                      <div class="from-label-value">
                        {{ format_date(AadharTabDetails.ak18) }}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-4 col-12">
                    <div class="customer-label-group mb-2">
                      <label class="form-label">Gender</label>
                      <div class="from-label-value">
                        {{
              AadharTabDetails.ak22
                ? AadharTabDetails.ak22 == 1
                  ? "Male"
                  : AadharTabDetails.ak22 == 2
                    ? "Female"
                    : "Others"
                : "N/A"
            }}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-4 col-12">
                    <div class="customer-label-group mb-2">
                      <label class="form-label">House</label>
                      <div class="from-label-value text-capitalize">
                        {{
                AadharTabDetails.ak148
                  ? AadharTabDetails.ak148
                  : "N/A"
              }}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-4 col-12">
                    <div class="customer-label-group mb-2">
                      <label class="form-label">Landmark</label>
                      <div class="from-label-value text-capitalize">
                        {{
                AadharTabDetails.ak50
                  ? AadharTabDetails.ak50
                  : "N/A"
              }}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-4 col-12">
                    <div class="customer-label-group mb-2">
                      <label class="form-label">Street</label>
                      <div class="from-label-value text-capitalize">
                        {{
                AadharTabDetails.ak147
                  ? AadharTabDetails.ak147
                  : "N/A"
              }}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-4 col-12">
                    <div class="customer-label-group mb-2">
                      <label class="form-label">Location</label>
                      <div class="from-label-value text-capitalize">
                        N/A
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-4 col-12">
                    <div class="customer-label-group mb-2">
                      <label class="form-label">Post Office</label>
                      <div class="from-label-value text-capitalize">
                        {{
                AadharTabDetails.ak92
                  ? AadharTabDetails.ak92
                  : "N/A"
              }}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-4 col-12">
                    <div class="customer-label-group mb-2">
                      <label class="form-label">Area</label>
                      <div class="from-label-value text-capitalize">
                        {{
                AadharTabDetails.ak44
                  ? AadharTabDetails.ak44
                  : "N/A"
              }}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-4 col-12">
                    <div class="customer-label-group mb-2">
                      <label class="form-label">City</label>
                      <div class="from-label-value text-capitalize">
                        {{
                AadharTabDetails.ak46
                  ? AadharTabDetails.ak46
                  : "N/A"
              }}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-4 col-12">
                    <div class="customer-label-group mb-2">
                      <label class="form-label">Sub Dist</label>
                      <div class="from-label-value text-capitalize">
                        {{
                AadharTabDetails.aa25
                  ? AadharTabDetails.aa25
                  : "N/A"
              }}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-4 col-12">
                    <div class="customer-label-group mb-2">
                      <label class="form-label">State</label>
                      <div class="from-label-value text-capitalize">
                        {{
                AadharTabDetails.ak146
                  ? AadharTabDetails.ak146
                  : "N/A"
              }}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-8 col-md-8 col-12">
                    <div class="customer-label-group mb-2">
                      <label class="form-label">Address</label>
                      <div class="from-label-value text-capitalize">
                        {{
                AadharTabDetails.ak42
                  ? AadharTabDetails.ak42
                  : "N/A"
              }}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-4 col-12">
                    <div class="customer-label-group mb-2">
                      <label class="form-label">Pincode</label>
                      <div class="from-label-value">
                        {{
                AadharTabDetails.ak97
                  ? AadharTabDetails.ak97
                  : "N/A"
              }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-4 col-md-4 col-12">
                    <div class="customer-label-group mb-2">
                      <label class="form-label">Last Updated Date</label>
                      <div class="from-label-value">
                        {{ format_date(AadharTabDetails.ak137) }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="showloaderaadharmodal">
              <div class="custom-modal-spinner-loader">
                <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
  <!-- view aadhar modal end here -->
  <!-- view voter modal start here -->
  <div class="modal-mask" v-if="viewvoterdetailmodalflag">
    <div class="modal-dialog modal-lg modal-dialog-centered custom-modal-outer">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">Voter Details</h5>
          <button type="button" class="btn-close" @click="viewprofilevoterModalClose()"></button>
        </div>
        <div class="modal-body ticket-history-box-outer">
          <div class="modal-aadhar-scroll-min-height">
            <div v-if="!showloadervotermodal">
              <!-- open modal to display if voter ID was exits are Single Only start here -->
              <div v-if="voterdivdisplayflag && voterarraylength == 1">
                <div class="voter-detail-card mb-3" v-for="votergovdata in voterTabDetails" :key="votergovdata">
                  <div class="row mb-2">
                    <div class="text-end">
                      <span v-if="votergovdata.ab26 == 1" class="status-primary">Primary</span>
                      <span v-else-if="votergovdata.ab26 == 2" class="status-secondary">Secondary</span>
                      <span v-else class="status-other">Other</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="customer-label-group mb-2">
                        <label class="form-label">Voter Number</label>
                        <div class="from-label-value text-uppercase">
                          {{
              votergovdata.ak35 ? votergovdata.ak35 : "N/A"
            }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="customer-label-group mb-2">
                        <label class="form-label">Name</label>
                        <div class="from-label-value text-capitalize">
                          {{
                votergovdata.ak5 ? votergovdata.ak5 : "N/A"
              }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-2 col-md-2 col-12">
                      <div class="customer-label-group mb-2">
                        <label class="form-label">Age</label>
                        <div class="from-label-value">
                          {{
                votergovdata.ak19 ? votergovdata.ak19 : "N/A"
              }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="customer-label-group mb-2">
                        <label class="form-label">Gender</label>
                        <div class="from-label-value text-capitalize">
                          {{
                votergovdata.ak22
                  ? votergovdata.ak22 == 1
                    ? "Male"
                    : votergovdata.ak22 == 2
                      ? "Female"
                      : "Others"
                  : "N/A"
              }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="customer-label-group mb-2">
                        <label class="form-label">Part Name</label>
                        <div class="from-label-value text-capitalize">
                          {{
                votergovdata.ab4 ? votergovdata.ab4 : "N/A"
              }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="customer-label-group mb-2">
                        <label class="form-label">Part Number</label>
                        <div class="from-label-value">
                          {{
                votergovdata.ak85 ? votergovdata.ak85 : "N/A"
              }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="customer-label-group mb-2">
                        <label class="form-label">Polling Station</label>
                        <div class="from-label-value text-capitalize">
                          {{
                votergovdata.ab7 ? votergovdata.ab7 : "N/A"
              }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="customer-label-group mb-2">
                        <label class="form-label">Area</label>
                        <div class="from-label-value text-capitalize">
                          {{
                votergovdata.ak44
                  ? votergovdata.ak44
                  : "N/A"
              }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="customer-label-group mb-2">
                        <label class="form-label">State</label>
                        <div class="from-label-value text-capitalize">
                          {{
                votergovdata.ak48
                  ? votergovdata.ak48
                  : "N/A"
              }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="customer-label-group mb-2">
                        <label class="form-label">Assembly Constituency</label>
                        <div class="from-label-value text-capitalize">
                          {{
                votergovdata.ab22
                  ? votergovdata.ab22
                  : "N/A"
              }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="customer-label-group mb-2">
                        <label class="form-label">Assembly Constituency Number</label>
                        <div class="from-label-value">
                          {{
                votergovdata.ab23
                  ? votergovdata.ab23
                  : "N/A"
              }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="customer-label-group mb-2">
                        <label class="form-label">Parliamentary Constituency</label>
                        <div class="from-label-value text-capitalize">
                          {{
                votergovdata.ab24
                  ? votergovdata.ab24
                  : "N/A"
              }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- open modal to display if voter ID was exits are Single Only end here -->
              <!-- open modal to display if voter ID was exits are Multiple start here -->
              <div class="d-flex align-items-start" v-if="voterarraylength > 1">
                <ul class="nav nav-tabs custom-vertical-modal-tab flex-column me-3" id="myTab" role="tablist">
                  <li class="nav-item" role="presentation" v-for="(votergovdata, index) in voterTabDetails"
                    :key="index">
                    <button class="nav-link" :class="votergovdata.ab26 == 1 ? 'active' : ''"
                      :id="'headingNumberSet' + index" data-bs-toggle="tab"
                      :data-bs-target="'#collapseVirtualNumber' + index" type="button" role="tab" aria-selected="true">
                      <span class="d-flex justify-content-between align-items-center"><span>{{ votergovdata.ak35
                          }}</span>
                        <span class="user-badge status-active" v-if="votergovdata.ab26 == 1"><i
                            class="pi pi-check-circle text-success me-1"
                            :style="{ fontSize: '10px' }"></i>Primary</span></span>
                    </button>
                  </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                  <div class="tab-pane fade" :class="votergovdata.ab26 == 1 ? 'show active' : ''"
                    :aria-labelledby="'headingNumberSet' + index" role="tabpanel" :id="'collapseVirtualNumber' + index"
                    v-for="(votergovdata, index) in voterTabDetails" :key="index">
                    <div class="row">
                      <div class="col-lg-6 col-md-6 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Voter Number</label>
                          <div class="from-label-value text-uppercase">
                            {{
              votergovdata.ak35 ? votergovdata.ak35 : "N/A"
            }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Name</label>
                          <div class="from-label-value text-capitalize">
                            {{
                votergovdata.ak5 ? votergovdata.ak5 : "N/A"
              }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Age</label>
                          <div class="from-label-value">
                            {{
                votergovdata.ak19 ? votergovdata.ak19 : "N/A"
              }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-6 col-md-6 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Gender</label>
                          <div class="from-label-value text-capitalize">
                            {{
                votergovdata.ak22
                  ? votergovdata.ak22 == 1
                    ? "Male"
                    : votergovdata.ak22 == 2
                      ? "Female"
                      : "Others"
                  : "N/A"
              }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Part Name</label>
                          <div class="from-label-value text-capitalize">
                            {{
                votergovdata.ab4 ? votergovdata.ab4 : "N/A"
              }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Part Number</label>
                          <div class="from-label-value">
                            {{
                votergovdata.ak85 ? votergovdata.ak85 : "N/A"
              }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Polling Station</label>
                          <div class="from-label-value text-capitalize">
                            {{
                votergovdata.ab7 ? votergovdata.ab7 : "N/A"
              }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Area</label>
                          <div class="from-label-value text-capitalize">
                            {{
                votergovdata.ak44
                  ? votergovdata.ak44
                  : "N/A"
              }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">State</label>
                          <div class="from-label-value text-capitalize">
                            {{
                votergovdata.ak48
                  ? votergovdata.ak48
                  : "N/A"
              }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Assembly Constituency</label>
                          <div class="from-label-value text-capitalize">
                            {{
                votergovdata.ab22
                  ? votergovdata.ab22
                  : "N/A"
              }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Assembly Constituency Number</label>
                          <div class="from-label-value">
                            {{
                votergovdata.ab23
                  ? votergovdata.ab23
                  : "N/A"
              }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Parliamentary Constituency</label>
                          <div class="from-label-value text-capitalize">
                            {{
                votergovdata.ab24
                  ? votergovdata.ab24
                  : "N/A"
              }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- open modal to display if voter ID was exits are Multiple end here -->
              <!-- when enter voter id display confirmation detail to update start here -->
              <div v-if="voterupdatedisplayflag">
                <div class="voter-detail-card mb-3">
                  <div class="row">
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="customer-label-group mb-2">
                        <label class="form-label">Voter Number</label>
                        <div class="from-label-value text-uppercase">
                          {{
              votergovconfirmdata.ak35 ? votergovconfirmdata.ak35 : "N/A"
            }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="customer-label-group mb-2">
                        <label class="form-label">Name</label>
                        <div class="from-label-value text-capitalize">
                          {{
                votergovconfirmdata.ak5 ? votergovconfirmdata.ak5 : "N/A"
              }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-2 col-md-2 col-12">
                      <div class="customer-label-group mb-2">
                        <label class="form-label">Age</label>
                        <div class="from-label-value">
                          {{
                votergovconfirmdata.ak19 ? votergovconfirmdata.ak19 : "N/A"
              }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="customer-label-group mb-2">
                        <label class="form-label">Gender</label>
                        <div class="from-label-value text-capitalize">
                          {{
              votergovconfirmdata.ak22
                ? votergovconfirmdata.ak22 == 1
                  ? "Male"
                  : votergovconfirmdata.ak22 == 2
                    ? "Female"
                    : "Others"
                : "N/A"
            }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="customer-label-group mb-2">
                        <label class="form-label">Part Name</label>
                        <div class="from-label-value text-capitalize">
                          {{
                votergovconfirmdata.ab4 ? votergovconfirmdata.ab4 : "N/A"
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="customer-label-group mb-2">
                        <label class="form-label">Part Number</label>
                        <div class="from-label-value">
                          {{
                          votergovconfirmdata.ak85 ? votergovconfirmdata.ak85 : "N/A"
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="customer-label-group mb-2">
                        <label class="form-label">Polling Station</label>
                        <div class="from-label-value text-capitalize">
                          {{
                          votergovconfirmdata.ab7 ? votergovconfirmdata.ab7 : "N/A"
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="customer-label-group mb-2">
                        <label class="form-label">Area</label>
                        <div class="from-label-value text-capitalize">
                          {{
                          votergovconfirmdata.ak44
                          ? votergovconfirmdata.ak44
                          : "N/A"
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="customer-label-group mb-2">
                        <label class="form-label">State</label>
                        <div class="from-label-value text-capitalize">
                          {{
                          votergovconfirmdata.ak48
                          ? votergovconfirmdata.ak48
                          : "N/A"
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="customer-label-group mb-2">
                        <label class="form-label">Assembly Constituency</label>
                        <div class="from-label-value text-capitalize">
                          {{
                          votergovconfirmdata.ab22
                          ? votergovconfirmdata.ab22
                          : "N/A"
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="customer-label-group mb-2">
                        <label class="form-label">Assembly Constituency Number</label>
                        <div class="from-label-value">
                          {{
                          votergovconfirmdata.ab23
                          ? votergovconfirmdata.ab23
                          : "N/A"
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="customer-label-group mb-2">
                        <label class="form-label">Parliamentary Constituency</label>
                        <div class="from-label-value text-capitalize">
                          {{
                          votergovconfirmdata.ab24
                          ? votergovconfirmdata.ab24
                          : "N/A"
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- when enter voter id display confirmation detail to update end here -->
            </div>
            <div v-if="showloadervotermodal">
              <div class="custom-modal-spinner-loader">
                <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- view voter modal end here -->
  
    <!-- confirmation Profile Delete Details modal start here -->
    <div class="modal-mask" v-if="confirm_delete_popup_status">
    <div class="modal-dialog modal-xs modal-dialog-centered custom-modal-outer confirm-dialog-modal">
      <div class="modal-content text-center">
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <div class="confirm-dialog-header">Confirmation</div>
            </div>
            <div class="col-12">
              <div class="d-flex align-items-center">
                <div class="flex-shrink-0">
                  <i class="pi pi-exclamation-triangle" style="font-size: 2rem"></i>
                </div>
                <div class="flex-grow-1 ms-3 confirm-dialog-body">
                  Are you sure you want to proceed?
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="col-12 text-end">
            <button type="button" :disabled="showconfirmdeleteloaderbtn" class="btn confirm-modal-no-btn"
              @click="confirmDeleteProfileModalClose()">
              No
            </button>
            <button type="button" class="btn confirm-modal-yes-btn" @click="deleteProfileSubmitBtn()"
              :disabled="showconfirmdeleteloaderbtn">
              <span v-if="!showconfirmdeleteloaderbtn">Yes</span>

              <div class="spinner-border text-light custom-spinner-loader-btn" role="status"
                v-if="showconfirmdeleteloaderbtn">
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- confirmation Profile Delete Details modal end here -->
</template>
<script>
import ApiService from "../service/ApiService";
import moment from 'moment';

export default {
  data() {
    return {
      profileupdate: {
        title: { value: 1, label: "Mr." },
        firstname: "",
        middlename: "",
        lastname: "",
        countrycode: { value: 101, label: "+91" },
        mobileno: "",
        useraadharno: "",
        uservoderid: '',
        useraadharaddress: '',
        usercurrentaddress: '',
        profileimage: [],
        
        uploadprofile: '',
        uploadphototype: '',
        votercreateimg: null,
      },
      titleList: [],
      countryCodeList: [],
      disableBtnBool: false,
      errStatus: false,
      errors: "",
      profilefilename: null,
      editRowId: '',

      errorprofileimage: '',
      submitted: false,
      errormsg: "",
      showsaveloader: false,
      viewaadhardetailmodalflag: false,
      viewvoterdetailmodalflag: false,
      AadharTabDetails: "",
      voterTabDetails: "",
      showloadervotermodal: false,
      showloaderaadharmodal: false,
      user_info: '',
      allowshowaadharvoterfk: '',
      confirm_aadhar_update_popup_status: false,
      aadhar_number_fk: '',
      showaconfirmloaderbtn: false,
      showupdateloader: false,
      aadhardivdisplayflag: 0,
      mobile_otp_status: false,
      aadharotp: "",
      displayMobilePopUp: false,
      timerMobileInterval: null,
      timerMobileStatus: false,
      timerMobileCount: 120,
      mobileotperr: "",
      aadharcardnumber: "",
      aadharerr: '',
      sendOTPMessage: '',
      showloader: false,
      alreadyExitMessage: '',
      systemlimiterrormsg: '',
      showresentloader: false,
      aadharupdatebtnflag: true,
      rowId: '',
      updateVoterBtnStatus: true,
      confirm_update_popup_status: false,
      showconfirmloaderbtn: false,
      voterupdatedisplayflag: false,
      voterarraylength: 0,
      voterinputdisplayflag: false,
      votercardnumber: "",
      voteriderr: "",
      alreadyExitVoterMessage: '',
      voterdivdisplayflag: false,
      errmsg: "",
      errorprocreateimage: '',
      aadharprofileimage: '',
      createpreviewurl: null,
      voterprofileimg: '',
      existsmanualphoto: '',
      setProfileTypePhoto: '',
      confirm_delete_popup_status: false,
      showconfirmdeleteloaderbtn: false,
    };
  },
  ApiService: null,
  created() {
    this.ApiService = new ApiService();
  },


  mounted() {
    if (localStorage.user) {
      var localdata = JSON.parse(localStorage.user);
      this.editRowId = localdata.user_id;
      this.user_title_fk = localdata.ak10;
      this.user_title_value = localdata.ak11;
      this.profileupdate.title = {
        label: this.user_title_value,
        value: this.user_title_fk,
      };
      this.rowId = localdata.ak1;
      this.profileupdate.firstname = localdata.ak7;
      this.profileupdate.middlename = localdata.ak8;
      this.profileupdate.lastname = localdata.ak9;
      this.profileupdate.email = localdata.ak23;
      this.profileupdate.mobileno = localdata.ak24;
      if (localdata.ak36) {
        this.profileupdate.useraadharno = this.maskedNumber(localdata.ak36);
      }
      this.profileupdate.useraadharno = localdata.ak36;
      this.profileupdate.uservoderid = localdata.ak35;
      this.profileupdate.useraadharaddress = localdata.ak42;
      this.profileupdate.usercurrentaddress = localdata.ak51;
      this.allowshowaadharvoterfk = localdata.ak107;
      this.voterprofileimg = localdata.user_image;
      this.aadharprofileimage = localdata.aadhar_image;
      this.setProfileTypePhoto = localdata.ak151;
      this.client_info = JSON.parse(localStorage.client_info);
      this.voterstorageimgpath = this.client_info.maa24;
    }
    // this.getcountrycode();
    this.getalltitle();
  },
  watch: {
    aadharcardnumber(aadharcardnumber) {
      this.errmsg = "";
      this.validateAadharCardNumber(aadharcardnumber);
      if (aadharcardnumber.length == 0) {
        this.aadharerr = "";
        this.errStatus = false;
      }
    },
    votercardnumber(votercardnumber) {
      this.errmsg = "";
      this.validateVoterIdNumber(votercardnumber);
      if (votercardnumber.length == 0) {
        this.voteriderr = "";
        this.errStatus = false;
      }
    },
  },
  methods: {
    maskedNumber(getvalue) {
      const maskedDigits = 'X'.repeat(getvalue.length - 4);
      const FirstThreeDigits = getvalue.slice(0, 2);
      const lastThreeDigits = getvalue.slice(-2);
      return FirstThreeDigits + maskedDigits + lastThreeDigits;
    },
    viewprofileaadharModalOpen() {
      this.viewaadhardetailmodalflag = true;
      this.getaadhardetails();
    },
    viewprofileaadharModalClose() {
      this.viewaadhardetailmodalflag = false;
      this.displayMobilePopUp = false;
      this.timerMobileInterval = null;
      this.timerMobileStatus = false;
      this.timerMobileCount = 120;
      this.mobileotperr = '';
      this.aadharerr = '';
      this.sendOTPMessage = '';
      this.showloader = false;
    },

    viewprofilevoterModalOpen() {
      this.viewvoterdetailmodalflag = true;
      this.getvoterdetails();
    },
    viewprofilevoterModalClose() {
      this.viewvoterdetailmodalflag = false;
      this.updateVoterBtnStatus = true;
      this.confirm_update_popup_status = false;
      this.showconfirmloaderbtn = false;
      this.voterupdatedisplayflag = false;
      this.voterarraylength = 0;
      this.voterinputdisplayflag = false;
      this.alreadyExitVoterMessage = '';
      this.voterdivdisplayflag = false;
    },
    getvoterdetails() {
      this.voterTabDetails = '';
      this.voterarraylength = '';
      this.showloadervotermodal = true;
      this.ApiService.addvoterdetails({ ak1: this.rowId, update: 0, ak24: this.profileupdate.mobileno }).then((data) => {
        if (data.success == true) {
          this.voterTabDetails = data.data;
          this.voterarraylength = data.data.length;
          this.voterdivdisplayflag = true;
          this.voterinputdisplayflag = true;
          this.showloadervotermodal = false;
          this.alreadyExitVoterMessage = '';
        } else {
          this.voterinputdisplayflag = true;
          this.voterdivdisplayflag = false;
          this.showloadervotermodal = false;
          this.alreadyExitVoterMessage = '';
        }
      });
    },
    getcountrycode(e) {
      this.ApiService.getcountrycode(e).then((data) => {
        if (data.status == 200) {
          this.countryCodeList = data.data;
        }
      });
    },
    getalltitle(e) {
      this.ApiService.getalltitle(e).then((data) => {
        if (data.status == 200) {
          this.titleList = data.data;
        }
      });
    },
    onPasteMobile(event) {
      let mobvalue = event.clipboardData.getData('text');
      if (/^[0-9]+$/.test(mobvalue)) return true;
      else event.preventDefault();
    },
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        $event.preventDefault();
      }
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
      } else {
        return 'N/A';
      }
    },
    logout() {
      this.ApiService.logout().then((data) => {
        if (data.status == 200) {
          var successMsg = data.message;
          this.$toast.open({
            message: successMsg,
            type: "success",
            duration: 3000,
            position: "top-right",
          });
          setTimeout(() => {
            this.v$.$reset();
          }, 0);
        } else {
          if (errorMsg != '') {
            var errorMsg = data.message;
            this.$toast.open({
              message: errorMsg,
              type: "error",
              duration: 3000,
              position: "top-right",
            });
          }
        }
      });
    },
    
    isLetterWithOutSpace(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[A-Za-z]+$/.test(char)) return true;
      else e.preventDefault();
    },
    // First Get Aadhar Details API call
    getaadhardetails() {
      this.aadharUpdateBtnDaysMsg = '';
      this.showloaderaadharmodal = true;
      this.ApiService.getaadhardetails({ ak1: this.rowId }).then((data) => {
        if (data.success == true) {
          this.AadharTabDetails = data.data;
          if (this.AadharTabDetails.ak36 != null && this.AadharTabDetails.ak36 != '') {
            this.aadhardivdisplayflag = 1;
            if (this.AadharTabDetails.update == 0) {
              this.aadharupdatebtnflag = false;
            } else {
              this.aadharupdatebtnflag = true;
              this.aadharUpdateBtnDaysMsg = '';
            }
          } else {
            this.aadhardivdisplayflag = 0;
          }
          this.showloaderaadharmodal = false;
        } else {
          this.showloaderaadharmodal = false;
        }
      });
    },
    confirmDeleteProfileModalOpen() {
      this.confirm_delete_popup_status = true;
    },
    confirmDeleteProfileModalClose() {
      this.confirm_delete_popup_status = false;
    },
    deleteProfileSubmitBtn() {
      this.showconfirmdeleteloaderbtn = true;
      this.ApiService.deleteProfile({ ak1: this.rowId }).then((data) => {
        if (data.success == true) {
          this.showconfirmdeleteloaderbtn = false;
          this.confirm_delete_popup_status = false;
          var successMsg = data.message;
          this.$toast.open({
            message: successMsg,
            type: "success",
            duration: 3000,
            position: "top-right",
          });
          setTimeout(() => {
            localStorage.clear();
            this.$router.push("/dashboard");
          }, 2000);
        } else {
          this.confirm_delete_popup_status = true;
          this.showconfirmdeleteloaderbtn = false;
          var errorMsg = data.message;
            this.$toast.open({
              message: errorMsg,
              type: "error",
              duration: 3000,
              position: "top-right",
            });
        }
      });
    },
  },
};
</script>
<style scoped>
.profile-reset-password-btn {
  letter-spacing: 0px;
  color: #3362db;
  font-size: 14px;
  font-family: "AcuminPro-Regular";
  padding: 0;
  text-decoration: none;
}

.profile-reset-password-btn:focus-visible {
  outline: 0;
}

.profile-reset-password-btn:focus {
  box-shadow: none;
}

.custom-image-upload>input {
  display: none;
}

.custom-image-upload {
  position: absolute;
  right: 0;
  bottom: 0;
}

.reset-password-submit-btn.btn {
  background: #5266e5 0% 0% no-repeat padding-box;
  border-color: #5266e5;
  border-radius: 4px;
  letter-spacing: 0.26px;
  color: #ffffff;
  font-size: 13px;
  line-height: 20px;
  padding: 6px 19px;
  font-family: "AcuminPro-Regular";
}

.reset-password-submit-btn:focus {
  box-shadow: none;
}

.upload-image-status-outer {
  padding: 5px 15px;
  background: #e4f8f0 0% 0% no-repeat padding-box;
  border: 1px solid #bbd9cc;
  border-radius: 4px;
  display: inline-block;
}

.upload-image-status-outer .from-label-value {
  font-family: "AcuminPro-SemiBold";
  font-size: 14px;
  letter-spacing: 0.28px;
  color: #2b2e38;
  line-height: 20px;
}

.upload-image-status-outer .form-label {
  font-family: "AcuminPro-Regular";
  font-size: 13px;
  letter-spacing: 0px;
  color: #4a5463;
  line-height: 13px;
  margin-bottom: 6px;
}

.custom-view-detail-btn {
  color: #5266E5;
  border-color: #5266E5;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #5266E5;
  border-radius: 4px;
  font-family: 'AcuminPro-Regular';
  font-size: 13px;
  letter-spacing: 0.26px;
  padding: 6px 19px;
  line-height: 20px;
}

.custom-view-detail-btn:hover {
  color: #5266E5;
}

.custom-view-detail-btn:focus-visible {
  outline: 0;
}

.profile-delete-btn {
  color: #dc3545;
  border-color: #dc3545;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #dc3545;
  border-radius: 4px;
  font-family: 'AcuminPro-Regular';
  font-size: 13px;
  letter-spacing: 0.26px;
  padding: 6px 19px;
  line-height: 20px;
}

.profile-delete-btn:hover {
  color: #dc3545;
}

.profile-delete-btn:focus-visible {
  outline: 0;
}

.modal-aadhar-scroll-min-height {
  position: relative;
  min-height: 365px;
  overflow-x: hidden;
  overflow-y: hidden;
}

.transition-effect-card-outer {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e0e5ed;
  border-radius: 4px;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
  cursor: pointer;
}

.transition-effect-card-outer:hover {
  -moz-transform: scale(1.04);
  -webkit-transform: scale(1.04);
  -o-transform: scale(1.04);
  -ms-transform: scale(1.04);
  transform: scale(1.04);
  background-color: #e7f1ff;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
  border: 1px solid #b7bfc9;
}

.voter-detail-card {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e0e5ed;
  border-radius: 4px;
  padding: 12px;
}

.status-primary {
  background: #c8e6c9;
  border-radius: 2px;
  letter-spacing: 0.3px;
  color: #256029;
  opacity: 1;
  font-size: 12px;
  font-family: "AcuminPro-Bold";
  padding: 0.3em 0.2rem 0.2em 0.2rem;
  text-transform: uppercase;
  border: 1px solid #4daa53;
  line-height: 12px;
}

.status-secondary {
  background: #ffd8b2;
  border-radius: 2px;
  letter-spacing: 0.3px;
  color: #805b36;
  opacity: 1;
  font-size: 12px;
  font-family: "AcuminPro-Bold";
  padding: 0.3em 0.2rem 0.2em 0.2rem;
  text-transform: uppercase;
  border: 1px solid #c79b6f;
  line-height: 12px;
}

.status-other {
  background: #ffcdd2;
  border-radius: 2px;
  letter-spacing: 0.3px;
  color: #c63737;
  opacity: 1;
  font-size: 12px;
  font-family: "AcuminPro-Bold";
  padding: 0.3em 0.2rem 0.2em 0.2rem;
  text-transform: uppercase;
  border: 1px solid #d7a5a5;
  line-height: 12px;
}

.user-badge {
  border-radius: 2px;
  padding: 0.3rem 0.3rem 0.1rem 0.3rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 11px;
  letter-spacing: 0.3px;
}

.imagehover {
  position: relative;
}

.imagehover:after {
  content: 'View';
  color: #fff;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  opacity: 0;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-family: 'AcuminPro-Bold';
  font-size: 25px;
}

.imagehover:hover:after {
  opacity: 1;
}
.aadhar-resent-otp-btn {
  letter-spacing: 0px;
  color: #3362db;
  font-size: 14px;
  font-family: "AcuminPro-Regular";
  padding: 0;
  text-decoration: none;
}

.aadhar-resent-otp-btn:focus-visible {
  outline: 0;
}

.aadhar-resent-otp-btn:focus {
  box-shadow: none;
}
</style>